import React, { useState, useEffect } from "react"
import * as Style from "./DropDown.module.scss"

const DropDown = ({
  areaData,
  placeholder,
  setPlaceholder,
  setArea,
  hasResetPlaceholder = null,
}) => {
  const [showDropDown, setShowDropDown] = useState(false)

  const ShowDropdownHandler = event => {
    setShowDropDown(!showDropDown)
  }

  const blurHandler = () => {
    if (showDropDown) {
      setTimeout(() => {
        setShowDropDown(false)
      }, 100)
    }
  }

  const [sortArr, setSortArr] = useState([])

  useEffect(() => {
    const sorted = [...areaData.nodes]
    sorted.sort((a, b) => a.area_bg.localeCompare(b.area_bg))
    const moved = [
      sorted.find(item => Number(item.post_code) === 6300),
      ...sorted.filter(item => Number(item.post_code) !== 6300),
    ]

    setSortArr(moved)
  }, [])

  return (
    <button
      onClick={ShowDropdownHandler}
      onBlur={blurHandler}
      className={Style.dropDwonWrapper}
      type="button"
    >
      <div className={Style.cursor}>
        <h4>{placeholder}</h4>
      </div>

      <div
        className={`${Style.dropDownMenu} ${
          showDropDown ? Style.displayDropDown : ""
        }`}
      >
        {hasResetPlaceholder && (
          <div
            onClick={() => {
              setPlaceholder(hasResetPlaceholder)
              setArea(null)
            }}
            key={hasResetPlaceholder}
            className={Style.areaInfoWrapper}
          >
            <div>
              <p>{hasResetPlaceholder}</p>
            </div>
          </div>
        )}

        {sortArr.map(item => {
          return (
            <div
              onClick={() => {
                setPlaceholder(
                  `${Number(item.post_code) !== 6300 ? "с." : "гр."} ${
                    item.area_bg
                  }`
                )
                setArea(item.area_en)
              }}
              key={item.id}
              className={Style.areaInfoWrapper}
            >
              <div>
                <p>{` ${Number(item.post_code) !== 6300 ? "с." : "гр."} ${
                  item.area_bg
                }`}</p>
                <p>{`п.к. ${item.post_code}`}</p>
              </div>
            </div>
          )
        })}
      </div>
    </button>
  )
}

export default DropDown
