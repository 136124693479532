// extracted by mini-css-extract-plugin
export var fileInput = "IdeaForm-module--fileInput--hzLUO";
export var imageCounter = "IdeaForm-module--imageCounter--dfX0X";
export var imageList = "IdeaForm-module--imageList--T+q9l";
export var imageListWrapper = "IdeaForm-module--imageListWrapper--WA31+";
export var imageNumber = "IdeaForm-module--imageNumber--e6aTe";
export var infoBox = "IdeaForm-module--infoBox--422kO";
export var loaderWrapper = "IdeaForm-module--loaderWrapper--5hbLb";
export var loadingPaddingWrapper = "IdeaForm-module--loadingPaddingWrapper--FvYm-";
export var pictureBtn = "IdeaForm-module--pictureBtn--dRWd+";
export var pictureForm = "IdeaForm-module--pictureForm--b5nJk";
export var recaptchaWrapper = "IdeaForm-module--recaptchaWrapper--SD8So";
export var recaptchaWrapperInvalid = "IdeaForm-module--recaptchaWrapperInvalid--FiMcw";
export var removePicture = "IdeaForm-module--removePicture--m0jSz";
export var slideDown = "IdeaForm-module--slide-down--PzgOY";
export var successBtn = "IdeaForm-module--successBtn--CJ0-E";
export var successMessageWrapper = "IdeaForm-module--successMessageWrapper--Z44dW";
export var successMessageWrapperPaddingWrapper = "IdeaForm-module--successMessageWrapperPaddingWrapper--Pagdq";
export var termsFormWrapper = "IdeaForm-module--termsFormWrapper--yYs9j";
export var termsFormWrapperInvalid = "IdeaForm-module--termsFormWrapperInvalid--Pf3ic";
export var termsLinkWrapper = "IdeaForm-module--termsLinkWrapper--FBjpI";
export var uploadIcon = "IdeaForm-module--uploadIcon--21qFf";
export var uploadWrapper = "IdeaForm-module--uploadWrapper--5EC+P";